<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/customers">Kunden</router-link></li>
              <li>{{ customer.first_name }} {{ customer.last_name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/customers" class="button button-grey button-small">Alle Kunden ›</router-link></p>
            </div>
          </header>

          <div class="well" style="margin-top:20px">
            <a @click="send_password" class="button button-orange button-small button-100">Zugangsdaten per E-Mail schicken (Achtung: Passwort wird hierbei geändert ) ›</a>
          </div>

          <form @submit="update_customer" class="form" method="post" style="margin-top:20px">

            <div class="form-wrap">
              <label for="email">E-Mail</label>
              <input v-model="customer.email" class="form-input" type="text" name="email" id="email">
            </div>

            <div class="form-wrap">
              <label for="company">Unternehmen</label>
              <input v-model="customer.company" class="form-input" type="text" name="company" id="company">
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap">
                  <label for="first_name">Vorname</label>
                  <input v-model="customer.first_name" class="form-input" type="text" name="first_name" id="first_name">
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap">
                  <label for="last_name">Nachname</label>
                  <input v-model="customer.last_name" class="form-input" type="text" name="last_name" id="last_name">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-16">
                <div class="form-wrap">
                  <label for="street_name">Straße</label>
                  <input v-model="customer.street_name" class="form-input" type="text" name="street_name" id="street_name">
                </div>
              </div>
              <div class="col-8">
                <div class="form-wrap">
                  <label for="street_number">Hausnummer</label>
                  <input v-model="customer.street_number" class="form-input" type="text" name="street_number" id="street_number">
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-8">
                <div class="form-wrap">
                  <label for="zip_code">Postleitzahl</label>
                  <input v-model="customer.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                </div>
              </div>
              <div class="col-16">
                <div class="form-wrap">
                  <label for="city">Stadt</label>
                  <input v-model="customer.city" class="form-input" type="text" name="city" id="city">
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <label for="phone_number">Telefonnummer</label>
              <input v-model="customer.phone_number" class="form-input" type="text" name="phone_number" id="phone_number">
            </div>

            <div class="form-wrap">
              <label for="password">Passwort</label>
              <input v-model="customer.password" class="form-input" type="password" name="password" id="password">
            </div>

            <div class="form-wrap">
              <label for="staff">Mitarbeiter?</label>
              <input type="checkbox" id="staff" v-model="customer.staff">
            </div>

            <div class="form-wrap">
              <label for="birth_date">Geburtsdatum</label>
              <input v-model="customer.birth_date" class="form-input" type="text" name="birth_date" id="birth_date" placeholder="01.01.1990">
            </div>

            <div class="form-wrap select-wrap">
              <label for="trust_level">Vertrauen</label>
              <select v-model="customer.trust_level" id="trust_level">
                <option value="good">Gut</option>
                <option value="medium">Mittel</option>
                <option value="bad">Schlecht</option>
              </select>
            </div>

            <div class="form-wrap">
              <label for="trust_description">Vertrauen Infos</label>
              <textarea v-model="customer.trust_description" class="form-input" rows="3" id="trust_description"></textarea>
            </div>

            <p>
              <input type="submit" value="Speichern ›" class="button button-orange button-small">
            </p>

          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      customer: {}
    }
  },
  methods: {
    get_customer() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.customer = response.data.customer;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_customer: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/customers/'+this.$route.params.id, this.customer, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert',
        });
        this.get_customer();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    },
    send_password() {
      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/customers/'+this.$route.params.id+'/send_password', this.customer, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Zugangsdaten erfolgreich verschickt',
        });
      })
    }
  },
  mounted () {
    this.get_customer();
  }
}
</script>

<style lang="scss">

</style>
